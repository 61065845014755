@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto.woff2") format("woff2"), url("../fonts/Roboto.woff") format("woff"), url("../fonts/Roboto.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Robotomedium.woff2") format("woff2"), url("../fonts/Robotomedium.woff") format("woff"), url("../fonts/Robotomedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Robotobold.woff2") format("woff2"), url("../fonts/Robotobold.woff") format("woff"), url("../fonts/Robotobold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #E5E5E5;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
.wrap {
  width: 100%;
  max-width: 1430px;
  margin: 0 auto;
  padding: 0 15px;
}
header {
  width: 100%;
  background-color: #fff;
  padding: 25px 0;
  margin-bottom: 25px;
}
header .header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .header__burger {
  display: none;
  position: relative;
  width: 25px;
  height: 17px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #000;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 7px;
}
header .header__burger span:nth-child(3) {
  top: 14px;
}
header .header__logo {
  height: 50px;
  width: auto;
}
header .header__logo img {
  height: 100%;
  width: auto;
}
header .header__nav {
  font-size: 30px;
  font-weight: 500;
}
header .header__nav ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header .header__nav li {
  margin-left: 80px;
}
header .header__nav li:first-child {
  margin-left: 15px;
}
main {
  flex: 1;
}
main.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
article {
  flex: 1;
}
.news {
  flex: 1;
  margin-bottom: 50px;
}
.news__caption {
  display: block;
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.news__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 -2%;
}
.news__item {
  flex-basis: 46%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 350px;
  margin: 0 2% 30px;
  padding: 25px 15px;
  color: #fff;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.news__item.item--full {
  flex-basis: 100%;
  height: 300px;
}
.news__item .item__title {
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
}
.news__item .item__link {
  display: block;
  width: 100%;
  max-width: 300px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  border: 3px solid #fff;
  border-radius: 10px;
  padding: 8px 15px;
}
.content {
  font-size: 22px;
  margin-bottom: 50px;
}
.content > * {
  margin-bottom: 15px;
}
.content h1,
.content h2,
.content h3 {
  font-weight: 500;
}
.content h1 {
  font-size: 40px;
}
.content h2 {
  font-size: 30px;
}
.content h3 {
  font-size: 25px;
}
.content a {
  color: #D51116;
  text-decoration: underline;
}
.content a:hover,
.content a:visited {
  text-decoration: none;
}
.content ul,
.content ol {
  padding-left: 40px;
}
.content img.full {
  width: 100%;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.content img.center {
  display: block;
  max-width: 100%;
  margin: 0 auto 12px;
}
.content table {
  width: 100%;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
}
.content table td {
  padding: 10px 5px;
  border: 1px solid #000;
  text-align: center;
}
aside {
  width: 400px;
  margin-left: 20px;
}
aside .sidebar-block {
  margin-bottom: 15px;
}
aside .sidebar-block--first {
  background-color: #fff;
}
aside .sidebar-block__title {
  display: block;
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  color: #D51116;
  margin-bottom: 20px;
}
aside .sidebar-block__text {
  font-size: 25px;
  padding: 0 10px 15px;
}
aside .sidebar-block__list-item {
  margin-bottom: 15px;
  padding: 0 10px 15px;
}
aside .sidebar-block__list-item .list-item__title {
  display: block;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 15px;
}
aside .sidebar-block__list-item .list-item__text {
  font-size: 22px;
  margin-bottom: 15px;
}
aside .sidebar-block__list-item .list-item__link {
  font-size: 26px;
  font-weight: 500;
  text-decoration: underline;
  color: #D51116;
}
aside .sidebar-block__list-item .list-item__link:hover {
  text-decoration: none;
}
footer {
  width: 100%;
  background-color: #fff;
}
footer .footer__copyright {
  font-weight: 500;
  text-align: center;
  padding: 14px 15px;
}
@media screen and (max-width: 1199px) {
  header .header__nav {
    font-size: 25px;
  }
  header .header__nav li {
    margin-left: 40px;
  }
  .news__item {
    flex-basis: 100%;
  }
}
@media screen and (max-width: 991px) {
  header {
    padding: 15px 0;
  }
  header .header__logo {
    height: 30px;
  }
  header .header__burger {
    display: block;
  }
  header .header__burger.active span:nth-child(1) {
    transform: rotate(33deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    transform: rotate(-33deg);
    transform-origin: left;
  }
  header .header__nav {
    position: absolute;
    top: -20px;
    left: 50px;
    background-color: #fff;
    padding: 10px 20px;
    max-width: 250px;
    font-size: 20px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  header .header__nav.active {
    top: 30px;
    opacity: 1;
    visibility: visible;
  }
  header .header__nav ul {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  header .header__nav ul li {
    margin: 0 0 12px 0;
  }
  header .header__nav ul li:first-child {
    margin-left: 0;
  }
  main.wrap {
    flex-direction: column;
  }
  aside {
    width: 100%;
    margin-left: 0;
  }
  .news__item {
    flex-basis: 46%;
  }
}
@media screen and (max-width: 768px) {
  .news__item {
    flex-basis: 100%;
    height: auto;
    min-height: 300px;
  }
  .news__item.item--full {
    height: auto;
    min-height: 300px;
  }
  .news__item .item__title {
    font-size: 30px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 480px) {
  .content img.left {
    float: none;
    margin-right: 0;
    width: 100%;
  }
  .content img.right {
    float: none;
    margin-left: 0;
    width: 100%;
  }
  .content ul,
  .content ol {
    padding-left: 0;
  }
}
