@import "fonts";

@red: #D51116;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #E5E5E5;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.wrap {
  width: 100%;
  max-width: 1430px;
  margin: 0 auto;
  padding: 0 15px;
}

header {
  width: 100%;
  background-color: #fff;
  padding: 25px 0;
  margin-bottom: 25px;

  .header {
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__burger {
      display: none;
      position: relative;
      width: 25px;
      height: 17px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #000;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          top: 14px;
        }
      }
    }
    &__logo {
      height: 50px;
      width: auto;

      img {
        height: 100%;
        width: auto;
      }
    }
    &__nav {
      font-size: 30px;
      font-weight: 500;

      ul {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      li {
        margin-left: 80px;

        &:first-child {
          margin-left: 15px;
        }
      }
    }
  }
}

main {
  flex: 1;

  &.wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

article {
  flex: 1;
}

.news {
  flex: 1;
  margin-bottom: 50px;

  &__caption {
    display: block;
    font-size: 40px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 -2%;
  }
  &__item {
    flex-basis: 46%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 350px;
    margin: 0 2% 30px;
    padding: 25px 15px;
    color: #fff;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

    &.item--full {
      flex-basis: 100%;
      height: 300px;
    }
    .item {
      &__title { 
        font-size: 40px;
        font-weight: 500;
        text-transform: uppercase;
      }
      &__link {
        display: block;
        width: 100%;
        max-width: 300px;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        border: 3px solid #fff;
        border-radius: 10px;
        padding: 8px 15px;
      }
    }
  }
}

.content {
  font-size: 22px;
  margin-bottom: 50px;

  & > * {
    margin-bottom: 15px;
  }

  h1, h2, h3 {
    font-weight: 500;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 25px;
  }
  
  a {
    color: @red;
    text-decoration: underline;
    
    &:hover,
    &:visited {
      text-decoration: none;
    }
  }

  ul,ol {
    padding-left: 40px;
  }
  
  img {
    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
    &.center {
      display: block;
      max-width: 100%;
      margin: 0 auto 12px;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;

    td {
      padding: 10px 5px;
      border: 1px solid #000;
      text-align: center;
    }
  }
}

aside {
  width: 400px;
  margin-left: 20px;

  .sidebar-block {
    margin-bottom: 15px;

    &--first {
      background-color: #fff;
    }
    &__title {
      display: block;
      font-size: 40px;
      font-weight: 500;
      text-align: center;
      color: @red;
      margin-bottom: 20px;
    }
    &__text {
      font-size: 25px;
      padding: 0 10px 15px;
    }
    &__list {}
    &__list-item {
      margin-bottom: 15px;
      padding: 0 10px 15px;

      .list-item {
        &__title {
          display: block;
          font-size: 26px;
          font-weight: 500;
          margin-bottom: 15px;
        }
        &__text {
          font-size: 22px;
          margin-bottom: 15px;
        }
        &__link {
          font-size: 26px;
          font-weight: 500;
          text-decoration: underline;
          color: @red;
          
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

footer {
  width: 100%;
  background-color: #fff;

  .footer {
    &__copyright {
      font-weight: 500;
      text-align: center;
      padding: 14px 15px;
    }
  }
}

@import "media";