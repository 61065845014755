@media screen and (max-width: 1199px) {
  header {
    .header {
      &__nav {
        font-size: 25px;

        li {
          margin-left: 40px;
        }
      }
    }
  }

  .news {
    &__item {
      flex-basis: 100%;
    }
  }
}

@media screen and (max-width: 991px) {
  header {
    padding: 15px 0;

    .header {
      &__logo {
        height: 30px;
      }
      &__burger {
        display: block;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(33deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-33deg);
              transform-origin: left;
            }
          }
        }
      }

      &__nav {
        position: absolute;
        top: -20px;
        left: 50px;
        background-color: #fff;
        padding: 10px 20px;
        max-width: 250px;
        font-size: 20px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;

        &.active {
          top: 30px;
          opacity: 1;
          visibility: visible;
        }
        ul {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          li {
            margin: 0 0 12px 0;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  main {
    &.wrap {
      flex-direction: column;
    }
  }

  aside {
    width: 100%;
    margin-left: 0;
  }

  .news {
    &__item {
      flex-basis: 46%;
    }
  }
}

@media screen and (max-width: 768px) {
  .news {
    &__item {
      flex-basis: 100%;
      height: auto;
      min-height: 300px;

      &.item--full {
        height: auto;
        min-height: 300px;
      }
      .item {
        &__title {
          font-size: 30px;
          margin-bottom: 40px;
        }
        &__link {

        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .content {
    img {
      &.left {
        float: none;
        margin-right: 0;
        width: 100%;
      }
      &.right {
        float: none;
        margin-left: 0;
        width: 100%;
      }
    }
    ul, ol {
      padding-left: 0;
    }
  }
}